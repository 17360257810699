.cards {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.cardsContainer {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0 auto;
}

.cardsWrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cardsItem {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
  height: auto;
  width: 490px;
  background-color: var(--white-color);
}

.cardsItemLink {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cardsItemPic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cardsItemPic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: var(--white-color);
  background-color: #1f98f4;
  box-sizing: border-box;
}

.cardsItemImg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cardsItemImg:hover {
  transform: scale(1.1);
}

.cardsItemInfo {
  padding: 20px 30px 30px;
}

.cardsItemText {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .contentBlogContainer {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cardsItems {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cardsItem {
    margin-bottom: 2rem;
    width: 90vw;
  }
  .cards {
    padding-top: 0;
  }
}
