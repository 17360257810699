.aboutMe {
  padding-top: 1rem;
  background-image: var(--background-image);
  min-height: 90vh;
}

@media screen and (max-width: 960px) {
  .aboutMe {
    display: block;
    min-height: 100vh;
  }
}
