.contact-container {
  padding: 5rem;
  display: flex;
  height: 90vh;
  background-image: var(--background-image);
  align-items: center;
  justify-content: space-evenly;
}
.EmailContactForm {
  width: 40vw;
  height: 50vh;
}
.contactMe {
  display: flex;
  width: 40vw;
  height: 50vh;
  justify-content: space-between;
  color: var(--white-color);
  flex-direction: column;
}

.contactMe > h1 {
  font-size: 5rem;
  color: var(--secondary-color);
}
.contactMe > p {
  font-size: 2.25rem;
}

.icon {
  margin-right: 1.125rem;
}

@media screen and (max-width: 960px) {
  .contact-container {
    padding: 3rem;
    display: table;
  }
  .EmailContactForm {
    width: 100%;
    height: fit-content;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .contactMe {
    display: flex;
    width: 100%;
    height: auto;
    align-items: baseline;
    text-wrap: balance;
  }

  .contactMe > h1 {
    font-size: 2rem;
    color: var(--secondary-color);
    align-self: center;
  }
  .contactMe > p {
    margin-top: 1rem;
    font-size: 1.2rem;
  }
}
