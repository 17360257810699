.section {
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 2%;
  padding-bottom: 2%;
  color: var(--white-color);
}

.subTitleSection {
  padding-top: 2%;
  padding-bottom: 2%;
}

.section > h1 {
  font-size: 48px;
}
.section > h1,
h3 {
  color: var(--secondary-color);
}
.subTitleSection > h2 {
  font-size: 32px;
}
.subTitleSection > h3,
p,
text {
  font-size: 24px;
}
.section > h1,
h2,
h3,
p,
text {
  font-family: Calibri, sans-serif;
  line-height: 150%;
}

.bullets {
  margin-left: 3%;
}

.doubleSection {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

@media only screen and (max-width: 768px) {
  .section > h1 {
    font-size: 26px;
  }
  .subTitleSection > h2 {
    font-size: 22px;
  }
  .subTitleSection > h3,
  p,
  text {
    font-size: 18px;
  }

  .subTitleSection {
    padding-top: 4%;
    padding-bottom: 4%;
  }
}
