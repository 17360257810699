.projects {
  height: 90vh;
  background-image: var(--background-image);
  width: 100%;
}
@media screen and (max-width: 960px) {
  .projects {
    display: table;
    min-height: max-content;
  }
}
