.resume {
  display: flex;
  background-image: var(--background-image);
  color: var(--white-color);
  flex-direction: column;
}

.doubleSection {
  padding-bottom: 10vh;
}

@media only screen and (max-width: 768px) {
  .section {
    margin-top: 2vh;
  }
}
