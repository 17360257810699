.contactForm {
  display: block;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contactForm > textarea {
  height: 20vh;
}

.contactElement,
.contactButton {
  margin-top: 32px;
  resize: none;
  padding: 10px 12px;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  border-color: #fff;
  border-width: 1px;
  border-style: solid;
  color: #fff;
  font-size: 16px;
}

.contactButton {
  margin-top: 40px;
  background-color: var(--secondary-color);
  color: var(--white-color);
  border: none;
}
.contactButton:hover {
  background-color: var(--white-color);
  color: var(--secondary-color);
  border-radius: 0;
  transition: all 0.3s ease-out;
}

.contactElement::placeholder {
  color: #e7d9d8;
}
